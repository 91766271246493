/* src/App.css */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Set box-sizing globally to ensure elements respect the width and padding */
html {
  box-sizing: border-box;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden; /* Ensure the body does not overflow horizontally */
}

/* Remove any unintended overflow or white space on mobile */
section {
  padding: 3rem 0;
  margin: 0 auto;
  max-width: 100%;
  overflow-x: hidden;
}

h1,
h2 {
  margin: 0 0 20px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

ul {
  padding: 0;
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

button {
  cursor: pointer;
}
