/* Style for the contact form section */
.contact-form {
  max-width: 800px; /* Limits the width of the contact form */
  margin: 0 auto; /* Centers the form horizontally */
  padding: 2rem;
  border-radius: 8px; /* Rounded corners for the form */
  text-align: center;
}

/* Heading style */
.contact-form h2 {
  font-size: 2rem;
  margin-bottom: 1.5rem;
  color: #444; /* Darker text for heading */
  text-transform: uppercase;
  letter-spacing: 1.5px; /* Adds spacing between letters */
}

/* Form label styles */
.contact-form label {
  display: block;
  font-size: 1rem;
  margin-bottom: 0.5rem;
  color: #333;
  text-align: left; /* Aligns label text to the left */
}

/* Input and textarea field styles */
.contact-form input,
.contact-form textarea {
  width: 100%; /* Full width of the form container */
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc; /* Light border around fields */
  border-radius: 4px; /* Rounded corners for inputs */
  font-size: 1rem;
  font-family: inherit;
  color: #333;
  background-color: #fff;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1); /* Subtle inset shadow for depth */
  transition:
    border 0.3s ease,
    box-shadow 0.3s ease; /* Smooth transition when focusing */
}

/* Focus state for input and textarea fields */
.contact-form input:focus,
.contact-form textarea:focus {
  border-color: #555; /* Darker border when focused */
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.2); /* Deeper shadow on focus */
  outline: none;
}

/* Submit button styles */
.contact-form button {
  padding: 0.75rem 2rem;
  font-size: 1rem;
  background-color: #444; /* Dark background for the button */
  color: #fff; /* White text color */
  border: none;
  border-radius: 4px; /* Rounded corners */
  cursor: pointer;
  transition: background-color 0.3s ease; /* Smooth transition for hover */
}

/* Hover effect for the submit button */
.contact-form button:hover {
  background-color: #666; /* Slightly lighter background on hover */
}

/* Status message styles */
.contact-form .status {
  margin-top: 1rem;
  font-size: 1rem;
  color: green; /* Success message color */
}

.contact-form .status.error {
  color: red; /* Error message color */
}

/* Responsive styles */
@media (max-width: 768px) {
  .contact-form {
    padding: 1.5rem;
  }

  .contact-form h2 {
    font-size: 1.75rem;
  }

  .contact-form button {
    padding: 0.75rem 1.5rem;
  }
}

@media (max-width: 480px) {
  .contact-form {
    padding: 1rem;
  }

  .contact-form h2 {
    font-size: 1.5rem;
  }

  .contact-form button {
    width: 100%; /* Full-width button on small screens */
    padding: 0.75rem;
  }
}
