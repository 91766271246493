/* Styling for the About section */
.about {
  padding: 4rem 2rem; /* Adds padding to the section */
  text-align: center; /* Center aligns the text */
  background-color: #f9f9f9; /* Light background for contrast */
  color: #333; /* Text color */
}

.about h2 {
  font-size: 2.5rem; /* Large heading font size */
  margin-bottom: 1.5rem; /* Space below the heading */
  text-transform: uppercase; /* Uppercase letters for the heading */
  letter-spacing: 2px; /* Adds space between letters */
  color: #444; /* Darker color for the heading */
}

.about p {
  font-size: 1.25rem; /* Slightly larger paragraph font size */
  line-height: 1.6; /* Space between lines for readability */
  max-width: 800px; /* Constrains the width of the paragraph */
  margin: 0 auto; /* Centers the paragraph */
  color: #555; /* Lighter text color */
}

/* Responsive styles */
@media (max-width: 768px) {
  .about h2 {
    font-size: 2rem; /* Smaller heading for tablets */
  }

  .about p {
    font-size: 1.15rem; /* Smaller paragraph text for tablets */
  }
}

@media (max-width: 480px) {
  .about h2 {
    font-size: 1.75rem; /* Even smaller heading for mobile */
  }

  .about p {
    font-size: 1rem; /* Smaller paragraph text for mobile */
  }
}
