/* src/components/AudioPlayer.css */

/* Audio player section styling */
.audio-player {
  padding: 4rem 2rem; /* Adds padding to the section */
  background-color: #f0f0f0; /* Light background for contrast */
  text-align: center; /* Center aligns the content */
  color: #333; /* Text color */
}

/* Audio player title */
.audio-player h2 {
  font-size: 2rem;
  margin-bottom: 1.5rem;
  color: #444; /* Darker text for heading */
  text-transform: uppercase;
  letter-spacing: 1.5px; /* Adds spacing between letters */
}

/* Audio element */
.audio-player audio {
  width: 70%;
  height: 40px;
  margin-bottom: 1rem;
  outline: none;
  max-width: 1200px;
}
