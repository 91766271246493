/* Style for the testimonials section */
.testimonials {
  padding: 4rem 2rem; /* Adds padding around the gallery */
  background-color: #f9f9f9; /* Light background color for contrast */
  text-align: center;
}

/* Style for the h2 heading */
.testimonials h2 {
  font-size: 2rem;
  margin-bottom: 1.5rem;
  color: #444; /* Darker text for heading */
  text-transform: uppercase;
  letter-spacing: 1.5px; /* Adds spacing between letters */
}

/* Container for the testimonials */
.testimonial-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}

/* Individual testimonial styling */
.testimonial {
  background: #fff;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 800px; /* Ensures testimonials are not too wide */
  width: 100%;
  text-align: left;
}

/* Style for testimonial feedback */
.feedback {
  font-size: 1.25rem; /* Slightly larger paragraph font size */
  line-height: 1.2; /* Space between lines for readability */
  max-width: 800px; /* Constrains the width of the paragraph */
  margin: 0 auto; /* Centers the paragraph */
  color: #555; /* Lighter text color */
}

/* Style for the testimonial name */
.name {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

/* Style for the testimonial role */
.role {
  color: #777;
  font-size: 0.875rem; /* Slightly smaller text for role */
}

/* Responsive styles */
@media (max-width: 768px) {
  .feedback {
    font-size: 1rem; /* Smaller for mobile */
  }
}
