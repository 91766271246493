/* Style for the gallery section */
.gallery {
  padding: 4rem 2rem; /* Adds padding around the gallery */
  background-color: #f9f9f9; /* Light background color for contrast */
  text-align: center;
}

.gallery h2 {
  font-size: 2.5rem; /* Large font size for the heading */
  margin-bottom: 2rem; /* Adds space below the heading */
  color: #444; /* Text color for the heading */
  text-transform: uppercase; /* Makes the heading uppercase */
  letter-spacing: 2px; /* Adds spacing between letters */
  width: 100%;
}

/* Grid layout for the gallery items */
.gallery-wrap {
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(250px, 1fr)
  ); /* Auto-fit adjusts columns based on screen size */
  grid-gap: 1.5rem; /* Space between grid items */
  justify-items: center; /* Centers grid items horizontally */
  padding: 2rem 0; /* Adds padding to the top and bottom */
}

/* Individual gallery item styling */
.gallery-item {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Adds a subtle shadow to each item */
}

/* Style for the gallery images */
.gallery-item img {
  width: 100%; /* Makes the image take up the entire width of its container */
  height: auto;
  display: block;
  object-fit: cover; /* Ensures the image covers its container */
  border-radius: 10px; /* Rounded corners for the images */
  transition:
    transform 0.3s ease,
    filter 0.3s ease; /* Smooth transition for hover effects */
}

/* Hover effect on gallery images */
.gallery-item:hover img {
  transform: scale(1.05); /* Slightly enlarges the image on hover */
  filter: brightness(0.8); /* Darkens the image on hover */
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
  .gallery h2 {
    font-size: 2rem; /* Smaller heading size for tablets */
  }

  .gallery-wrap {
    grid-template-columns: repeat(
      auto-fit,
      minmax(200px, 1fr)
    ); /* Adjusts columns for smaller screens */
  }
}

@media (max-width: 480px) {
  .gallery h2 {
    font-size: 1.75rem; /* Even smaller heading size for mobile */
  }

  .gallery-wrap {
    grid-template-columns: repeat(
      auto-fit,
      minmax(150px, 1fr)
    ); /* Adjusts columns for mobile screens */
  }
}
