/* Header container styling */
.header {
  background-color: #333; /* Dark background for contrast */
  padding: 1rem 0; /* Padding for top and bottom */
  position: fixed; /* Keep the header fixed at the top */
  width: 100%;
  top: 0;
  z-index: 1000; /* Ensures the header is above other content */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

/* Centering the content inside the header */
.header .container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 2rem; /* Padding for sides */
  display: flex;
  justify-content: space-between; /* Space between logo and navigation */
  align-items: center;
}

/* Logo styling */
.logo {
  font-size: 1.8rem;
  color: #fff; /* White logo text */
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
}

/* Navigation menu styling */
nav ul {
  list-style: none; /* Remove bullet points */
  display: flex;
  gap: 1.5rem; /* Space between menu items */
}

nav ul li {
  margin: 0;
}

nav ul li a {
  text-decoration: none; /* Remove underline */
  color: #fff; /* White text for the navigation links */
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: color 0.3s ease; /* Smooth hover transition */
}

nav ul li a:hover {
  color: #f9a825; /* Change color on hover */
}

/* Mobile Menu */
@media (max-width: 768px) {
  .header {
    position: static; /* Make the header non-fixed on mobile devices */
  }
  .header .container {
    flex-direction: column; /* Stack items vertically on smaller screens */
    align-items: flex-start;
  }

  nav ul {
    flex-direction: column; /* Stack menu items */
    gap: 1rem; /* Add more space between stacked items */
    margin-top: 1rem;
  }

  nav ul li a {
    font-size: 0.8rem; /* Increase font size for better readability */
  }
}
