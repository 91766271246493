/* Hero section styling */
.hero {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh; /* Full viewport height */
  width: 100%;
  padding-top: 60px; /* Add padding-top equal to the header height */
  text-align: center;
  color: #fff;
  overflow: hidden; /* To prevent the background image from overflowing */
}

.hero::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("../../public/hero.webp") no-repeat center center/cover;

  /* Apply filters to the background image */
  opacity: 0.9;
  -webkit-filter: blur(3px); /* Safari */
  -moz-filter: blur(3px); /* Firefox */
  -o-filter: blur(3px); /* Opera */
  -ms-filter: blur(3px); /* IE */
  filter: blur(3px); /* Standard */

  z-index: -1; /* Ensures the image stays behind the text */
}

/* Hero content styling */
.hero-content {
  max-width: 1400px; /* Keeps content centered and constrained */
  padding: 0 2rem; /* Adds padding for small screens */
  z-index: 1; /* Ensures content stays above the background image */
}

.hero h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
  text-transform: uppercase;
  letter-spacing: 3px;
  line-height: 1.2;
  max-width: 1400px;
}

.hero p {
  font-size: 1.5rem;
  line-height: 1.5;
  margin-top: 0.5rem;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .hero h1 {
    font-size: 3rem; /* Slightly smaller for medium screens */
  }

  .hero p {
    font-size: 1.3rem; /* Adjusts paragraph size */
  }
}

@media (max-width: 768px) {
  .hero h1 {
    font-size: 2.5rem; /* Further reduce font size on smaller screens */
  }

  .hero p {
    font-size: 1.2rem;
  }
}

@media (max-width: 480px) {
  .hero h1 {
    font-size: 2rem; /* Smaller font for very small screens */
  }

  .hero p {
    font-size: 1rem;
  }
}
