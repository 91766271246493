/* Styling for the Services section */
.services {
  padding: 4rem 2rem; /* Adds padding to the section */
  text-align: center; /* Center aligns the text */
  background-color: #f9f9f9; /* Light background for contrast */
  color: #333; /* Text color */
}

.services h2 {
  font-size: 2.5rem; /* Large heading font size */
  margin-bottom: 1.5rem; /* Space below the heading */
  text-transform: uppercase; /* Uppercase letters for the heading */
  letter-spacing: 2px; /* Adds space between letters */
  color: #444; /* Darker color for the heading */
}

.services p {
  font-size: 1.25rem; /* Slightly larger paragraph font size */
  line-height: 1.6; /* Space between lines for readability */
  max-width: 800px; /* Constrains the width of the paragraph */
  margin: 0 auto; /* Centers the paragraph */
  color: #555; /* Lighter text color */
}

/* Styling for the service list */
.service-list {
  list-style-type: none; /* Removes default bullet points */
  padding: 0; /* Removes padding */
  margin: 2rem 0; /* Adds spacing above and below the list */
}

.service-list li {
  font-size: 1.25rem; /* Same font size as the paragraph */
  margin-bottom: 0.5rem; /* Adds spacing between list items */
  color: #444; /* Darker text color for the list */
}

.service-list li strong {
  font-weight: bold; /* Bold style for emphasis */
  color: #222; /* Slightly darker color for strong text */
}

/* Styling for the tailored packages paragraph */
.tailored-packages {
  font-size: 1.2rem; /* Adjusted font size */
  color: #333; /* Standard text color */
  margin-top: 1.5rem; /* Adds space above this paragraph */
  font-style: italic; /* Adds emphasis through italic style */
}

/* Responsive styles */
@media (max-width: 768px) {
  .services h2 {
    font-size: 2rem; /* Smaller heading for tablets */
  }

  .services p {
    font-size: 1.15rem; /* Smaller paragraph text for tablets */
  }

  .service-list li {
    font-size: 1.15rem; /* Smaller list text for tablets */
  }
}

@media (max-width: 480px) {
  .services h2 {
    font-size: 1.75rem; /* Even smaller heading for mobile */
  }

  .services p,
  .service-list li {
    font-size: 1rem; /* Smaller paragraph and list text for mobile */
  }

  .tailored-packages {
    font-size: 1rem; /* Smaller tailored packages text for mobile */
  }
}
