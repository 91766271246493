/* General footer styles */
.footer {
  background-color: #333; /* Dark background for the footer */
  color: #fff; /* White text color */
  padding: 2rem;
  text-align: center;
  position: relative;
  bottom: 0;
  width: 100%;
}

.footer-content {
  max-width: 1200px; /* Limit the footer's width */
  margin: 0 auto;
  display: flex;
  flex-direction: column; /* Stacks the content */
  align-items: center; /* Center the content horizontally */
}

/* Style for the copyright text */
.footer-content p {
  font-size: 1rem;
  margin-bottom: 1rem;
  color: #ddd; /* Slightly lighter color for the text */
}

/* Social icon container */
.social-icons {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 1rem;
}

/* Style for individual social icons */
.social-icons a {
  color: #fff; /* White color for the icons */
  font-size: 1.5rem; /* Size of the icons */
  transition: color 0.3s ease; /* Smooth transition for hover */
}

/* Hover effect for social icons */
.social-icons a:hover {
  color: #1da1f2; /* Changes color on hover, customize for different platforms if needed */
}

/* Responsive design */
@media (max-width: 768px) {
  .footer-content {
    padding: 1.5rem;
  }

  .footer-content p {
    font-size: 0.9rem;
  }

  .social-icons a {
    font-size: 1.25rem; /* Slightly smaller icons on smaller screens */
  }
}

@media (max-width: 480px) {
  .footer {
    padding: 1.5rem;
  }

  .footer-content p {
    font-size: 0.85rem;
  }

  .social-icons {
    gap: 0.75rem; /* Reduce gap between icons on small screens */
  }

  .social-icons a {
    font-size: 1.2rem; /* Smaller icon size on mobile */
  }
}
